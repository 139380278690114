import DownloadButton from "../DownloadButton/DownloadButton";
import "./Inventory.scss";
import Help from "../Help/Help";
import { useState } from "react";
import ClickSound from "../../assets/audio/click.wav";

function Inventory({ collectedGifts }) {
  const [showHelp, setShowHelp] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);

  const playClickSound = () => {
    const audio = new Audio(ClickSound);
    audio.play();
  };

   // Open Help Modal
   const toggleHelp = () => {
    playClickSound();
    setShowHelp((prevShowHelp) => !prevShowHelp);
    setShowCloseButton((prevShowCloseButton) => !prevShowCloseButton);
  };

  const hideHelp = () => {
    playClickSound();
    setShowHelp(false);
    setShowCloseButton(false);
  };


  return (
    <>
      <section className="inventory-container">
        <ul className="inventory">
          {collectedGifts.map((giftsData, index) => (
            <li key={index}>
              <img
                src={giftsData.iconUrl}
                alt={giftsData.alt}
                className={`gift-item gift-item--${giftsData.type}`}
              />
            </li>
          ))}
        </ul>
        <section className="button-container">
          <DownloadButton collectedGifts={collectedGifts} />
          {showHelp && <Help />}
        {showCloseButton && (
          <button
            onClick={hideHelp}
            className="help-screen__button help-screen__button--close"
          >
            X
          </button>
        )}
        {!showHelp && (<button onClick={toggleHelp} className="help-screen__button">
            ?
          </button>)}
        
        </section>
      </section>
    </>
  );
}

export default Inventory;
