import GamePage from "./Pages/GamePage/GamePage";
import LandingPage from "./Pages/LandingPage/LandingPage";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SpeedInsights } from '@vercel/speed-insights/react';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/play" element={<GamePage />} />
      </Routes>
      <SpeedInsights />
    </BrowserRouter>
    
    </>
  );
}

export default App;
