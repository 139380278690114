import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function GiftMushroom({ onCollected, collectedGifts, playerPosition }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/assets/models/gift-mushroom-transformed.glb"
  );
  const { actions } = useAnimations(animations, group);
  const scaleFactor = 0.5;
  const scaleFactorCollected = 0;
  const startingPosition = [7.5, 0.5, -7.3];
  const giftName = "Gift-5";
  const isCollected = collectedGifts.some((gift) => gift.name === giftName);

  React.useEffect(() => {
    if (actions && actions["SphereAction"]) {
      actions["SphereAction"].play();
    }
  }, [actions]);

  return (
    <group
      ref={group}
      dispose={null}
      position={startingPosition}
      scale={[
        isCollected ? scaleFactorCollected : scaleFactor,
        isCollected ? scaleFactorCollected : scaleFactor,
        isCollected ? scaleFactorCollected : scaleFactor,
      ]}
    >
      <group name="Scene">
        <group name="Sphere" scale={scaleFactor}>
          <mesh
            name="Sphere_1"
            geometry={nodes.Sphere_1.geometry}
            material={materials.clear}
          />
          <mesh
            name="Sphere_2"
            geometry={nodes.Sphere_2.geometry}
            material={materials["gift-frog"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/gift-mushroom-transformed.glb");
