import Game from "../../components/Game/Game";
import Inventory from "../../components/Inventory/Inventory";
import Help from "../../components/Help/Help";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

import "./GamePage.scss";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Character from "../../assets/icons/gift-frog.png";
import PlantBorderBottom from "../../assets/images/plant-border.png";
import Tree from "../../assets/images/tree.png";
import Strawberry from "../../assets/icons/strawberry.png";
import ClickSound from "../../assets/audio/click.wav";
import Mushroom from "../../assets/icons/gift-mushroom.png";
import Frog from "../../assets/icons/gift-frog.png";

function GamePage() {
  const [collectedGifts, setCollectedGifts] = useState([]);
  // const [showHelp, setShowHelp] = useState(false);
  // const [showCloseButton, setShowCloseButton] = useState(false);

  const playClickSound = () => {
    const audio = new Audio(ClickSound);
    audio.play();
  };

  // // Open Help Modal
  // const toggleHelp = () => {
  //   playClickSound();
  //   setShowHelp((prevShowHelp) => !prevShowHelp);
  //   setShowCloseButton((prevShowCloseButton) => !prevShowCloseButton);
  // };

  // const hideHelp = () => {
  //   playClickSound();
  //   setShowHelp(false);
  //   setShowCloseButton(false);
  // };

  // No Scrolling with Arrow Keys
  useEffect(() => {
    const preventScrollingWithArrowKeys = (event) => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", preventScrollingWithArrowKeys);

    return () => {
      document.removeEventListener("keydown", preventScrollingWithArrowKeys);
    };
  }, []);

  return (
    <section className="app-container">
      <img
        src={PlantBorderBottom}
        className="overlay__plant-bottom"
        alt="3D bushes and grass"
      ></img>
      <img src={Tree} className="overlay__tree" alt="3D tree"></img>
      <section className="mobile-message">
        <h1>Please use a desktop device to explore Bug Forest</h1>
        <img
          src={Character}
          className="mobile-message__character"
          alt="3D frog"
        ></img>
        <Link to="/" onClick={playClickSound}>
          <button>Go Home</button>
        </Link>
      </section>
      <div className="ui-container">
        <div className="title-bar">
          <Link to="/" onClick={playClickSound}>
            <div className="app-title__container">
              <img
                className="app-title__icon"
                src={Strawberry}
                alt="strawberry icon"
              ></img>
              <h1 className="app-title">
                .•´*¨`*•✿ Welcome to Bug Forest! ✿•*`¨*`•.
              </h1>
              <img
                className="app-title__icon"
                src={Strawberry}
                alt="strawberry icon"
              ></img>
            </div>
          </Link>
        </div>
        {/* {showHelp && <Help />}
        {showCloseButton && (
          <button
            onClick={hideHelp}
            className="help-screen__button help-screen__button--close"
          >
            X
          </button>
        )} */}
        <div className="loading-screen-container">
          <LoadingScreen />
        </div>
        <div className="windows-container">
          {/* <button onClick={toggleHelp} className="help-screen__button">
            ?
          </button> */}
          <Game
            setCollectedGifts={setCollectedGifts}
            collectedGifts={collectedGifts}
          />
          <Inventory collectedGifts={collectedGifts} />
        </div>
        <div className="footer__container">
          <section className="footer__social-container">
            <a
              className="social-container__link"
              href="https://www.rebekahheppner.net"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-container__image"
                src={Mushroom}
                alt="mushroom icon"
              ></img>
              <button
                className="social-container__button"
                onClick={playClickSound}
              >
                Website
              </button>
            </a>
            <a
              className="social-container__link"
              href="mailto: rebekahheppner@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-container__image"
                src={Frog}
                alt="mushroom icon"
              ></img>
              <button
                className="social-container__button"
                onClick={playClickSound}
              >
                Email
              </button>
            </a>
            <a
              className="social-container__link"
              href="https://www.instagram.com/rebekahheppner/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-container__image"
                src={Strawberry}
                alt="mushroom icon"
              ></img>
              <button className="social-container__button onClick={playClickSound}">
                Instagram
              </button>
            </a>
          </section>
        </div>
      </div>
    </section>
  );
}

export default GamePage;
