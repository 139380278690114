import React, { useRef, useState, useEffect, useCallback } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";

import giftsData from "../../data/gifts.json";
import SparkleSound from "../../assets/audio/sparkle.mp3";

// CONSTANTS
const SPEED = 0.028;
const ROTATION_SPEED = 0.022;
const SCALE_FACTOR = 0.23;
const STARTING_POSITION = [-3.5, 0.48, -3];

// THIRD PERSON CAMERA
function ThirdPersonCamera({ playerRef }) {
  const { camera } = useThree();
  camera.near = 0.4;

  useFrame(() => {
    if (playerRef.current) {
      // Offset the camera behind the player
      const offset = new THREE.Vector3(0, 0.3, 1);
      offset.applyQuaternion(playerRef.current.quaternion);
      const position = playerRef.current.position.clone().add(offset);

      // Set the camera position and look at the player
      camera.position.copy(position);
      camera.lookAt(playerRef.current.position);
    }
  });

  return null;
}

// PLAYER
export function Player({
  collectedGifts,
  setCollectedGifts,
  onPositionChange,
  environmentObjects,
}) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/assets/models/playerAnimated-transformed.glb"
  );
  const frameCounterRef = useRef(0);

  // Preload and cache the sparkle sound
  const sparkleAudio = React.useMemo(() => {
    const audio = new Audio(SparkleSound);
    audio.volume = 0.3;
    return audio;
  }, []);

  const playSparkleSound = React.useCallback(() => {
    sparkleAudio.currentTime = 0;
    sparkleAudio.play();
  }, [sparkleAudio]);

  // ANIMATION
  const { actions } = useAnimations(animations, group);

  // Play animation when the component mounts
  React.useEffect(() => {
    if (actions && actions["Fairy_Circle.006Action"]) {
      actions["Fairy_Circle.006Action"].play();
    }
  }, [actions]);

  // PLAYER NAVIGATION
  const [position, setPosition] = useState(STARTING_POSITION);
  const [rotation, setRotation] = useState([0, 0, 0]);

  // Key presses
  const pressedKeysRef = useRef(new Set());

  const handleKeyDown = (event) => {
    pressedKeysRef.current.add(event.key);
  };

  const handleKeyUp = (event) => {
    pressedKeysRef.current.delete(event.key);
  };

  const movePlayer = () => {
    const raycaster = new THREE.Raycaster();
    const forwardDirection = new THREE.Vector3();
    const playerPosition = new THREE.Vector3(...position);
    let isMoving = false; // Track whether the player is moving

    // Determine rotation direction based on pressed keys
    if (pressedKeysRef.current.has("ArrowLeft")) {
      setRotation((prevRotation) => [0, prevRotation[1] + ROTATION_SPEED, 0]);
    }
    if (pressedKeysRef.current.has("ArrowRight")) {
      setRotation((prevRotation) => [0, prevRotation[1] - ROTATION_SPEED, 0]);
    }

    // Movement based on direction
    if (
      pressedKeysRef.current.has("ArrowUp") ||
      pressedKeysRef.current.has("ArrowDown")
    ) {
      const moveForward = pressedKeysRef.current.has("ArrowUp");
      const dx = (moveForward ? -SPEED : SPEED) * Math.sin(rotation[1]);
      const dz = (moveForward ? -SPEED : SPEED) * Math.cos(rotation[1]);

      forwardDirection.set(dx, 0, dz).normalize();

      if (dx !== 0 || dz !== 0) {
        isMoving = true;
      }

      // Cast ray from player's current position in the direction they are moving
      raycaster.set(playerPosition, forwardDirection);
      const intersects = raycaster.intersectObjects(environmentObjects, true);

      if (intersects.length > 0 && intersects[0].distance < 0.3) {
        // Collision detected within 0.3 units, don't move
      } else {
        // No collision, update the player's position
        setPosition((prevPosition) => [
          prevPosition[0] + dx,
          prevPosition[1],
          prevPosition[2] + dz,
        ]);
      }
    }

    // Perform camera collision checks only if the player is moving
    if (isMoving) {
      const checkCameraCollision = () => {
        const raycaster = new THREE.Raycaster();
        const forwardDirection = new THREE.Vector3(0, 0, -1).applyQuaternion(
          group.current.quaternion
        );

        // Set the raycaster's origin to the camera's position
        raycaster.set(group.current.position, forwardDirection);

        // Check for intersections with the wall meshes
        const intersects = raycaster.intersectObjects(environmentObjects, true);

        if (intersects.length > 0) {
          // If there is an intersection, prevent camera rotation
          return true;
        }
        return false;
      };

      // Call the camera collision check
      checkCameraCollision();
    }
  };

  // Attach event listeners for keydown and keyup events
  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // GIFT COLLECTION
  // Calculate the distance between two 3D points
  const calculateDistance = useCallback((point1, point2) => {
    const dx = point1[0] - point2.x;
    const dy = point1[1] - point2.y;
    const dz = point1[2] - point2.z;
    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  }, []);

  const [playerGiftPositions, setPlayerGiftPositions] = useState([
    { name: "Gift-1", position: [-6.7, 0.5, -3] },
    { name: "Gift-2", position: [-7, 0.5, -7.5] },
    { name: "Gift-3", position: [2, 0.5, -2.3] },
    { name: "Gift-4", position: [4.4, 0.5, -5.3] },
    { name: "Gift-5", position: [7.5, 0.5, -7.3] },
    { name: "Gift-6", position: [-1.2, 0.5, -8.9] },
  ]);
  const [isCollectingGift, setIsCollectingGift] = useState(false);

  const checkGiftCollect = () => {
    if (isCollectingGift) return;

    for (const { name, position } of playerGiftPositions) {
      const distance = calculateDistance(position, group.current.position);
      if (distance < 0.19) {
        setIsCollectingGift(true);
        playSparkleSound();

        // Find the gift data and update collected gifts
        const collectedGiftData = giftsData.gifts.find(
          (gift) => gift.name === name
        );

        if (collectedGiftData) {
          const { iconUrl, alt, giftUrl } = collectedGiftData;
          setCollectedGifts((prevCollectedGifts) => [
            ...prevCollectedGifts,
            { name, type: getGiftType(name), iconUrl, alt, giftUrl },
          ]);
        }

        // Remove the collected gift from positions
        setPlayerGiftPositions((prevPositions) =>
          prevPositions.filter((gift) => gift.name !== name)
        );

        // Notify other components (if needed)
        // console.log(`${name} collected!`);

        // Reset collection flag after a delay
        setTimeout(() => setIsCollectingGift(false), 1000);
        break;
      }
    }
  };

  const getGiftType = useCallback((giftName) => {
    return giftName === "Gift-4" || giftName === "Gift-3"
      ? "special"
      : "normal";
  }, []);

  //Ref to track whether movement is happening
  const isMovingRef = useRef(false);

  useFrame(() => {
    // Only increment when actually needed
    if (pressedKeysRef.current.size > 0) {
      frameCounterRef.current++;

      // More efficient frame-based checks
      if (frameCounterRef.current % 15 === 0) {
        checkGiftCollect();
      }

      isMovingRef.current = true;
      movePlayer();
    } else if (isMovingRef.current) {
      isMovingRef.current = false;
      // Only call if position actually changed
      onPositionChange(group.current.position.toArray());
    }
  });

  return (
    <group
      ref={group}
      position={position}
      rotation={rotation}
      scale={[SCALE_FACTOR, SCALE_FACTOR, SCALE_FACTOR]}
      dispose={null}
    >
      <ThirdPersonCamera playerRef={group} />
      <group name="Scene">
        <group
          name="Fairy_Circle006"
          position={[0, -0.424, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <mesh
            name="Fairy_Circle006_1"
            geometry={nodes.Fairy_Circle006_1.geometry}
            material={materials.ground1}
          />
          <mesh
            name="Fairy_Circle006_2"
            geometry={nodes.Fairy_Circle006_2.geometry}
            material={materials["testColor.006"]}
          />
          <mesh
            name="Fairy_Circle006_3"
            geometry={nodes.Fairy_Circle006_3.geometry}
            material={materials.fairyWings}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/playerAnimated-transformed.glb");
