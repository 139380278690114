import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function GiftFrog({ onCollected, collectedGifts, playerPosition }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/assets/models/gift-frog-transformed.glb"
  );
  const { actions } = useAnimations(animations, group);
  const scaleFactor = 0.2;
  const scaleFactorCollected = 0;
  const startingPosition = [4.4, 0.5, -5.3];
  const giftName = "Gift-4";
  const isCollected = collectedGifts.some((gift) => gift.name === giftName);

  // Start playing the animation when the component mounts
  useEffect(() => {
    if (actions && actions["SphereAction"]) {
      actions["SphereAction"].play();
    } else {
      console.log("Error: Unable to play animation");
    }
  }, [actions]);


  return (
    <group
      ref={group}
      dispose={null}
      position={startingPosition}
      scale={[
        isCollected ? scaleFactorCollected : scaleFactor,
        isCollected ? scaleFactorCollected : scaleFactor,
        isCollected ? scaleFactorCollected : scaleFactor,
      ]}
    >
      <group name="Scene">
        <group name="Sphere">
          <mesh
            name="Sphere_1"
            geometry={nodes.Sphere_1.geometry}
            material={materials.clear}
          />
          <mesh
            name="Sphere_2"
            geometry={nodes.Sphere_2.geometry}
            material={materials["gift-frog"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/gift-frog-transformed.glb");
